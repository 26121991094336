import React from "react";
import { Container } from "react-bootstrap";

import Gallery from "@browniebroke/gatsby-image-gallery";
import "@browniebroke/gatsby-image-gallery/dist/style.css";
import Header from "../components/header.jsx";
import Footer from "../components/footer";
import Seo from "../components/seo";

import { graphql, Link } from "gatsby";
const {MARGIN_TOP} = require("../utils/constants");


const Automotive = ({ data }) => {
  const sectionName = data.contentfulSection.name;
  const description = data.contentfulSection.description;
  const images = data.allContentfulWork.edges.map(({ node }) => ({
    ...node.photo,
    caption:
      node.instagramUrl !== null ? (
        <a
          className="mr-3"
          href={node.instagramUrl}
          aria-label="MG on Youtube"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#FFFFFF" }}
        >
          <i className="fab fa-instagram" /> Guardala su Instagram
        </a>
      ) : null,
  }));

  return (
    <React.Fragment>
      <Seo
        title={sectionName + " | mg98photographer"}
        description="Foto del mondo automotive scattate da me."
        url="https://www.mg98photographer.it/automotive"
        keywords={[
          [
            "marco garbi",
            "mg98photographer",
            "mg98",
            "fotografo",
            "fotografo treviso",
            "fotostampa",
            "ph",
            "ph treviso",
            "automotive",
            "foto macchine treviso"
          ],
        ]}
      >
        {/*FontAwesome  icons*/}
        <link
          href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
          rel="stylesheet"
        />
      </Seo>
      <Header style={{ zIndex: "1" }} />
      <Container style={{ marginTop: `${MARGIN_TOP}px` }}>
        <div className="mb-3">
          <Link style={{ color: "black" }} to="/"><i className="fas fa-home" /> Home</Link>
        </div>
        <h2
          className="mb-4"
          style={{ textAlign: "center", textTransform: "uppercase" }}
        >
          {sectionName}
        </h2>
        {description && (<p>{description.description}</p>)}
        <Gallery images={images} />
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default Automotive;

export const query = graphql`
  {
    allContentfulWork(
      filter: { section: { eq: "automotive" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id
          instagramUrl
          section
          name
          photo {
            thumb: fluid(maxWidth: 470, maxHeight: 470) {
              ...GatsbyContentfulFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulSection(description: {description: {}}, route: {eq: "/automotive"}) {
      name
      description {
        description
      }
    }
  }
`;
